export interface ContactIconProps {
  className?: string
  strokeColor?: string
}

export const MapIcon: React.FC<ContactIconProps> = ({
  className = '',
  strokeColor = '#111827',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66663 17L2.21941 14.2764C1.88063 14.107 1.66663 13.7607 1.66663 13.382V2.61803C1.66663 1.87465 2.44894 1.39116 3.11384 1.72361L7.66663 4M7.66663 17L13.6666 14M7.66663 17V4M13.6666 14L18.2194 16.2764C18.8843 16.6088 19.6666 16.1253 19.6666 15.382V4.61803C19.6666 4.23926 19.4526 3.893 19.1138 3.72361L13.6666 1M13.6666 14V1M13.6666 1L7.66663 4"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
export const CalendarIcon: React.FC<ContactIconProps> = ({
  className = '',
  strokeColor = '#111827',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66663 5V1M14.6666 5V1M5.66663 9H15.6666M3.66663 19H17.6666C18.7712 19 19.6666 18.1046 19.6666 17V5C19.6666 3.89543 18.7712 3 17.6666 3H3.66663C2.56206 3 1.66663 3.89543 1.66663 5V17C1.66663 18.1046 2.56206 19 3.66663 19Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)

export const LocationIcon: React.FC<ContactIconProps> = ({
  className = '',
  strokeColor = '#2F3148',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.619 15.6361C13.6862 16.5705 11.7366 18.5232 10.3927 19.8692C9.61138 20.6518 8.3447 20.6522 7.56339 19.8696C6.24294 18.547 4.33108 16.6321 3.33666 15.6361C0.221115 12.5156 0.221115 7.45624 3.33666 4.33572C6.4522 1.2152 11.5035 1.2152 14.619 4.33572C17.7346 7.45624 17.7346 12.5156 14.619 15.6361Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9695 9.98592C11.9695 11.6408 10.6301 12.9824 8.97784 12.9824C7.32557 12.9824 5.98615 11.6408 5.98615 9.98592C5.98615 8.33101 7.32557 6.98945 8.97784 6.98945C10.6301 6.98945 11.9695 8.33101 11.9695 9.98592Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
export const ClockIcon: React.FC<ContactIconProps> = ({
  className = '',
  strokeColor = '#2F3148',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97507 6.47706V10.4724L12.9668 13.4688M18.9501 10.4724C18.9501 15.4371 14.9319 19.4618 9.97507 19.4618C5.01828 19.4618 1 15.4371 1 10.4724C1 5.50764 5.01828 1.48294 9.97507 1.48294C14.9319 1.48294 18.9501 5.50764 18.9501 10.4724Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
export const FlagIcon: React.FC<ContactIconProps> = ({
  className = '',
  strokeColor = '#2F3148',
}) => (
  <div className={className}>
    <svg
      width="100%"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.92773 19.4547V15.4594M1.92773 15.4594V3.47351C1.92773 2.37024 2.82068 1.47586 3.92219 1.47586H10.4042L11.4014 2.47468H19.8779L16.8862 8.46762L19.8779 14.4606H11.4014L10.4042 13.4617H3.92219C2.82068 13.4617 1.92773 14.3561 1.92773 15.4594ZM10.9028 1.97527V7.4688"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
