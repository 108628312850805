import { ApolloError } from '@apollo/client'
import { Activity } from 'src/activities'
import { Button, ButtonVariants } from '@/components/elements/Button'
import ClientOnly from '@/components/ClientOnly'
import {
  GetNumberOfRegistrationsForActivityQuery,
  RegistrationType,
} from '@/src/graphql-server/types/types'

type ActivityButtonLabels = {
  apiErrorLabel?: string
  unknownErrorLabel?: string
  checkingTicketsLabel?: string
  bookingLabel?: string
  upcomingLabel?: string
  closedLabel?: string
  noTicketsLabel?: string
}

export interface ActivityCardProps {
  activity: Activity
  href: string
  labels?: ActivityButtonLabels
  variant?: ButtonVariants
  checkForSeats?: boolean
  data?: GetNumberOfRegistrationsForActivityQuery
  loading?: boolean
  error?: ApolloError
  userRole?: RegistrationType | null
}

const defaultLabels: ActivityButtonLabels = {
  apiErrorLabel: 'Hi ha hagut un error',
  unknownErrorLabel: 'No disponible',
  checkingTicketsLabel: 'Comprovant disponibilitat',
  bookingLabel: 'Reserva',
  upcomingLabel: 'Aviat disponible',
  closedLabel: 'No disponible',
  noTicketsLabel: 'Places esgotades',
}

export function ActivityButton({
  activity,
  href,
  labels = defaultLabels,
  variant = 'default',
  checkForSeats = true,
  data,
  loading,
  error,
  userRole = null,
}: ActivityCardProps): JSX.Element {
  const registrationOpeningDate = Date.parse(activity.openingDate)
  const registrationClosingDate = Date.parse(activity.closingDate)

  const registrationOpen =
    Date.now() >= registrationOpeningDate &&
    Date.now() <= registrationClosingDate &&
    registrationOpeningDate <= registrationClosingDate

  const registrationNotAvailable = Date.now() < registrationOpeningDate

  const registrationExpired = Date.now() > Date.parse(activity.closingDate)

  const checkAvailability = () => {
    if (registrationNotAvailable) {
      return { labelButton: labels.upcomingLabel, disabled: true }
    }
    if (registrationExpired) {
      return { labelButton: labels.closedLabel, disabled: true }
    }

    if (checkForSeats) {
      let maxSeatsReached = false
      if (userRole === RegistrationType.Volunteer) {
        maxSeatsReached =
          data &&
          data.registrationsForActivity >= activity.numberOfVolunteerSeats
      } else if (userRole === RegistrationType.Learner) {
        maxSeatsReached =
          data && data.registrationsForActivity >= activity.numberOfLearnerSeats
      } else {
        maxSeatsReached =
          data &&
          data.registrationsForActivity >=
            activity.numberOfVolunteerSeats + activity.numberOfLearnerSeats
      }

      if (maxSeatsReached) {
        return { labelButton: labels.noTicketsLabel, disabled: true }
      }

      if (error) {
        return { labelButton: labels.apiErrorLabel, disabled: true }
      }

      if (loading) {
        return { labelButton: labels.checkingTicketsLabel, disabled: true }
      }
    }

    if (registrationOpen) {
      return { labelButton: labels.bookingLabel, disabled: false }
    }

    return { labelButton: labels.unknownErrorLabel, disabled: true }
  }

  const { labelButton, disabled } = checkAvailability()
  const hrefButton = !disabled ? { href } : null

  return (
    <ClientOnly>
      <Button
        {...hrefButton}
        label={labelButton}
        variant={variant}
        disabled={disabled}
      />
    </ClientOnly>
  )
}
