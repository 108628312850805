import { Image } from '@/components/elements/Image'

export interface DividerProps {
  className?: string
  small?: boolean
}

export const Divider: React.FC<DividerProps> = ({
  className = '',
  small = false,
}) => (
  <div className={`relative w-full h-2 pointer-events-none my-10 ${className}`}>
    <Image
      alt=""
      src={
        small
          ? '/img/horizontal-divider-small.svg'
          : '/img/horizontal-divider.svg'
      }
      layout="fill"
      unoptimized
      priority
      objectPosition="50% 50%"
      objectFit="none"
    />
  </div>
)
