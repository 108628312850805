import { Activity } from 'src/activities'
import Link, { LinkProps } from 'next/link'
import { useQuery } from '@apollo/client'
import { GetNumberOfRegistrationsForActivityQuery } from '@/src/graphql-server/types/types'
import { Image } from '@/components/elements/Image'
import { ActivityButton } from '@/components/elements/Button/activity'
import formatDate from '../../../utils/formatDate'
import { CalendarIcon, MapIcon } from '@/components/elements/ActivityCardIcons'
import GET_NUMBER_OF_REGISTRATIONS_FOR_ACTIVITY_QUERY from '../../../apollo/queries/getNumberOfRegistrationsForActivity.graphql'

export interface ActivityCardProps {
  activity: Activity
}

export function ActivityCard({ activity }: ActivityCardProps): JSX.Element {
  const checkDates = () => {
    return activity
  }

  const { data } = useQuery<GetNumberOfRegistrationsForActivityQuery>(
    GET_NUMBER_OF_REGISTRATIONS_FOR_ACTIVITY_QUERY,
    {
      variables: {
        activityId: activity.id,
      },
    }
  )

  return (
    <Link href={`/activities/${activity.id}`} passHref>
      <div className="card-shadow flex flex-col justify-between pb-10 h-full font-body rounded-2xl cursor-pointer">
        <div className="">
          <div className="aspect-w-6 aspect-h-5 md:aspect-w-16 md:aspect-h-9">
            <Image
              src={activity.thumbnail}
              alt={activity.title}
              layout="fill"
              objectFit="cover"
              className="rounded-t-2xl"
            />
          </div>
          <div className="flex flex-col pt-10 px-10">
            <span className="font-bold uppercase">{activity.category}</span>
            <strong className="mt-2 font-display text-3xl font-black">
              {activity.title}
            </strong>
            <div className="flex items-center mt-2 font-semibold">
              <MapIcon className="mr-2 w-5 h-5" />
              {activity.venue}
            </div>
            <div className="flex items-center mb-4 mt-2 font-semibold">
              <CalendarIcon className="mr-2 w-5 h-5" />
              {formatDate(activity.date)}
            </div>
          </div>
        </div>
        <div className="px-10">
          <ActivityButton
            data={data}
            activity={activity}
            href={`/activities/${activity.id}`}
            checkForSeats
          />
        </div>
      </div>
    </Link>
  )
}
